import React, { useCallback, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import AddToCart from "./AddToCart";
const ProductView = (props) => {
  return (
    <div key={"content"} className="product-content-wrapper">
      <div className="product-page-title">{props.selectedProduct.name}</div>
      <div className="product-page">
        <ImageGallery items={props.selectedProduct.images} />
        <div className="product-page-body">
          <div className="product-page-price">
            ${props.selectedProduct.price}
          </div>
          <AddToCart
            handleClick={(e, quantity) => {
              props.handleCart(e, props.selectedProduct, quantity);
            }}
          />
          <div
            className="product-page-description"
            dangerouslySetInnerHTML={{
              __html: props.selectedProduct.description,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductView;
