import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import "react-dropdown/style.css";

import { StoreNavigation } from "./store_components/StoreNavigation";
import Breadcrumbs from "./store_components/BreadCrumbs";
import ProductView from "./store_components/ProductView";
import CategoryView from "./store_components/CategoryView";
const all_products_category_slug = "featured-items";
const cs2n_url =
  process.env.NODE_ENV == "development"
    ? "http://localhost:5000/"
    : "https://www.cs2n.org/";

const cs2n_store_categories = "client_api/v1/get_store_categories/";
const cs2n_store_products = "client_api/v1/get_store_product/";

const CmraStore = (props) => {
  const [categories, setCategories] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [cmraStore, setCmraStore] = useState(null);
  const [storeQuantity, setStoreQuantity] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    check_store();
    get_categories();
  }, [null]);

  useEffect(() => {
    if (cmraStore) {
      var total_products = 0;
      var total_price = 0;
      for (let index = 0; index < cmraStore.length; index++) {
        const item = cmraStore[index];
        total_products = total_products + parseInt(item.quantity);
        total_price =
          total_price +
          parseInt(item.quantity) * parseFloat(item.product.price);
      }
      setTotal(total_price);
      setStoreQuantity(total_products);
    }
  }, [cmraStore]);

  useEffect(() => {
    const category_param_slug = serializeParams(
      window.location.search
    ).category;
    if (categories) {
      if (category_param_slug) {
        get_categories(category_param_slug);
      } else {
        const product = serializeParams(window.location.search).product;
        if (product) {
          get_selected_product(product);
        } else {
          get_categories(all_products_category_slug);
        }
      }
    }
  }, [categories]);

  const serializeParams = function (searchString) {
    let params = {};
    const keypairs = searchString.replace("?", "").split("&");
    keypairs.forEach((kp) => {
      const kp_array = kp.split("=");
      params[kp_array[0]] = kp_array[1];
    });
    return params;
  };

  const get_categories = async (slug = null) => {
    const options = {
      method: "GET",
      url: cs2n_url + cs2n_store_categories,
      params: { slug: slug },
      headers: { Accept: "application/vnd.api+json" },
    };
    try {
      const { data } = await axios.request(options);

      if (slug == null) {
        // Filter out the "hidden" category
        const filteredData = data.filter(
          (category) => category.slug !== "hidden"
        );
        filteredData.sort(
          (a, b) => parseInt(a.sort_order) - parseInt(b.sort_order)
        );
        setCategories(filteredData);
        //console.log(filteredData);
      } else {
        setSelectedCategory(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const get_selected_product = async (slug) => {
    const options = {
      method: "GET",
      url: `${cs2n_url + cs2n_store_products}`,
      params: { slug: slug },
      headers: { Accept: "application/vnd.api+json" },
    };

    try {
      const { data } = await axios.request(options);
      setSelectedProduct(data);
    } catch (error) {
      console.error(error);
    }
  };

  const get_product_category = (product) => {
    if (product) {
      for (let i = 0; i < product.categories.length; i++) {
        const element = product.categories[i];
        if (element.name != all_products_category_slug) {
          return element;
        }
      }
    } else {
      return null;
    }
  };
  const check_store = () => {
    var store = localStorage.getItem("cmra_store");
    if (store) {
      setCmraStore(JSON.parse(store));
    }
  };
  const handleCart = useCallback((_e, product, quantity) => {
    var store = localStorage.getItem("cmra_store");
    if (store) {
      store = JSON.parse(store);
      var hasItem = false;

      for (let index = 0; index < store.length; index++) {
        const item = store[index];

        if (item.slug == product.slug) {
          item.quantity = String(parseInt(item.quantity) + parseInt(quantity));
          // save store
          localStorage.setItem("cmra_store", JSON.stringify(store));
          setCmraStore(store);
          hasItem = true;
          break;
        }
      }
      if (!hasItem) {
        store.push({
          slug: product.slug,
          quantity: String(quantity),
          product: product,
        });
        localStorage.setItem("cmra_store", JSON.stringify(store));
        setCmraStore(store);
      }
    } else {
      var store = [
        { slug: product.slug, quantity: String(quantity), product: product },
      ];
      localStorage.setItem("cmra_store", JSON.stringify(store));

      setCmraStore(store);
    }
  });

  return (
    <div className="store_body">
      <CartBasket quantity={storeQuantity} />
      <img
        className="banner-image"
        src="https://cs2n.s3.amazonaws.com/assets/static/store/CMRA+Store+Banner_01_store.png"
      />
      <div className="store-front-wrapper">
        <div className="taxon_navigation">
          <StoreNavigation
            categories={categories}
            selectedCategory={
              selectedProduct
                ? get_product_category(selectedProduct)
                : selectedCategory
            }
          />
        </div>
        <div className="content-body">
          <Breadcrumbs
            key={"breadcrumbs"}
            category={
              selectedCategory
                ? selectedCategory
                : get_product_category(selectedProduct)
            }
            home_link={all_products_category_slug}
          />

          {selectedProduct ? (
            <ProductView
              selectedProduct={selectedProduct}
              handleCart={handleCart}
            />
          ) : (
            selectedCategory && (
              <CategoryView
                selectedCategory={selectedCategory}
                handleCart={handleCart}
              />
            )
          )}
        </div>
        <div className="total-bg">
          <span className="total-summary-label">
            <img
              src={
                "https://cs2n.s3.amazonaws.com/assets/static/store/Cart_empty.png"
              }
            />
            Order Summary
          </span>
          <span className="total-property">
            <span>Subtotal:</span>
            <span className="bold-property">${total.toFixed(2)}</span>
          </span>
          <span className="total-property">
            <span>Est Tax:</span>
            <span className="bold-property">${"0.00"}</span>
          </span>
          <span className="total-property">
            <span>Est Shipping & Handling:</span>
            <span className="bold-property">${"0.00"}</span>
          </span>
          <span className="total-property">
            <span>Total:</span>
            <span className="bold-property-total">${total.toFixed(2)}</span>
          </span>
          <div
            className="checkout-button"
            onClick={() => {
              location.href = "cmra_cart";
            }}
          >
            <span>Go To Cart:</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CartBasket = (props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (props.quantity > 0) {
      setAnimate(true);
      const timeoutId = setTimeout(() => setAnimate(false), 300); // Duration should match the animation length
      return () => clearTimeout(timeoutId); // Cleanup on component unmount
    }
  }, [props.quantity]);

  return (
    <div
      className="cart_basket"
      onClick={() => {
        location.href = "cmra_cart";
      }}
    >
      <i
        className="shopping_icon fa fa-shopping-cart fa-3"
        aria-hidden="true"
      ></i>
      <span className={`basket_value ${animate ? "bounce" : ""}`}>
        {props.quantity}
      </span>
    </div>
  );
};

export default CmraStore;
